<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h3>Hreflang groups</h3>
          <span v-if="page.page_hreflang_group_id" class="text-muted d-flex align-self-center">
            <i class="uil uil-info-circle mr-2"></i>Changes made here are saved automatically
          </span>
          <div class="d-flex justify-content-end">
            <div v-if="page.page_hreflang_group_id !== null">
              <button
                class="btn btn-danger"
                @click="readOnly ? null : unlinkPage(page, true)"
                :disabled="readOnly"
                v-bind:class="{ loading: isLoading }"
              >
                Unlink from group
                <i class="uil uil-link"></i>
              </button>
            </div>
            <div>
              <span @click="openModalInformation()" class="btn btn-outline-info btn-md ml-3">
                <i class="uil uil-info-circle"></i>Help
              </span>
            </div>
          </div>
        </div>
        <div class="card-body" id="seo-page-groups-body">
          <data-table
            v-if="page.page_hreflang_group_id"
            ref="table"
            :path="path()"
            :showEditModal="false"
            :enableDataExport="false"
            :column-templates="columnTemplates()"
            :rowActionButtons="
              readOnly
                ? []
                : [
                    {
                      action: unlinkPage,
                      title: 'Unlink',
                      icon: 'uil uil-link',
                      class: 'btn btn-info',
                    },
                  ]
            "
            :defaultLimit="999999"
            :showCardHeader="false"
            :columns="columns()"
            :customRowClick="rowClick"
            :showRemove="false"
            stateName="hreflang-table"
          />
          <div v-else>
            <p>This page is not linked to any other pages</p>
            <button
              class="btn btn-primary mr-2"
              @click="readOnly ? null : $refs.modelSelect.show()"
              v-bind:class="{ loading: isLoading }"
            >
              Link to page
              <i class="uil uil-link"></i>
            </button>
            <model-select
              model="page"
              ref="modelSelect"
              :show-field="false"
              :valueLabel="pageValueLabel"
              modal-size-class="modal-lg2"
              :custom-column-templates="modelSelectColumnTemplates()"
              :custom-columns="modelSelectCustomColumns()"
              :parameters="{
                site_id: page.site_id,
                template_id: page.template_id,
                where_key_not: page.id,
                status: 'active',
                where_market_not: page.market_id,
                bypass_market_restrictions: true,
              }"
              :return-full-object="true"
              label="Link to existing hreflang group"
              @valuePicked="valuePicked"
            />
          </div>
        </div>
      </div>
    </div>
    <information-modal ref="informationModal" :id="confluenceId" />
  </div>
</template>
<script lang="jsx">
import ModelSelect from '@molecules/fields/model-select/base-model-select.vue'
import BasicOptions from '@mixins/BasicOptions.js'
import PageHreflangService from '@services/PageHreflangService.js'
import PagesService from '@services/PagesService.js'
import PageStatusBadge from '@atoms/table/cell/pages-status.vue'
import TableColumnTemplate from '@constants/table-column-template.js'
import InformationModal from '@molecules/cms/modals/information-modal.vue'
import confluenceIds from '@constants/confluence-ids.js'
export default {
  data() {
    return {
      isLoading: false,
      pageValueLabel: null,
      confluenceId: 0,
    }
  },
  components: {
    ModelSelect,
    PageStatusBadge,
    InformationModal,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    readOnly: {
      default: false,
    },
  },
  created() {
    this.confluenceId = confluenceIds.pages.seo.hreflang.id
  },
  updated() {
    this.$emit('updated-date')
  },
  methods: {
    openModalInformation() {
      this.$refs.informationModal.show()
    },
    async valuePicked(value, valueLabel) {
      this.isLoading = true
      this.pageValueLabel = valueLabel
      // already has a hreflang group
      if (value.page_hreflang_group_id) {
        await PagesService.update({ id: this.page.id, page_hreflang_group_id: value.page_hreflang_group_id })
          .then((response) => {
            if (response.data.success) {
              this.page.page_hreflang_group_id = response.data.result.page_hreflang_group_id
              this.$nextTick(function () {
                this.$refs.table.search()
              })
            } else {
              this.showErrorMessages(response.data.messages)
            }
          })
          .catch(this.showUnknownErrorMessage)
          .finally(() => {
            this.isLoading = false
          })
      } else {
        // new hreflang group
        await PageHreflangService.linkPages(this.page.id, value.id)
          .then((response) => {
            if (response.data.success) {
              this.page.page_hreflang_group_id = response.data.result.id
            } else {
              this.showErrorMessages(response.data.messages)
            }
          })
          .catch(this.showUnknownErrorMessage)
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    async unlinkPage(row, unlinkSelf = false) {
      this.$emit('updated-date')
      if (this.isLoading) return false
      const self = this
      this.isLoading = true
      this.$refs.table.isLoading = true

      await PageHreflangService.unlinkPage(row.id, this.$route.params.id)
        .then((response) => {
          if (response.data.success) {
            self.$refs.table.search()
            self.showSuccessMessage('Page unlinked')
            if (unlinkSelf) {
              self.page.page_hreflang_group_id = null
            } else if (this.page.id === row.id) {
              self.page.page_hreflang_group_id = null
            } else if (response.data.result.disbanded || false) {
              // hreflang group disbanded
              self.page.page_hreflang_group_id = null
            }
          } else {
            self.showErrorMessages(response.data.messages)
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.isLoading = false
        })
    },
    path() {
      return `hercules/sites/pages?page_hreflang_group_id=${this.page.page_hreflang_group_id}&with=market&bypass_market_restrictions=true`
    },
    columns() {
      return [
        { field: 'title', type: 'text', filterable: false, sortable: false },
        {
          field: 'path',
          type: 'text',
          filterable: false,
          clipboard: true,
          clickable: false,
          sortable: false,
        },
        { field: 'market', type: 'text', label: 'Market', filterable: false, sortable: false },
        { field: 'status', type: 'checkbox', filterable: false, sortable: false },
        { field: 'region_code', type: 'text', label: 'Region code', filterable: false },
        {
          field: 'updated_at',
          type: 'text',
          filterable: false,
          sortable: false,
          template: TableColumnTemplate.RELATIVE_DATE,
        },
      ]
    },
    columnTemplates() {
      return Object.assign(
        BasicOptions.relativeDateTemplate(['updated_at']),
        BasicOptions.activeTemplate(['active']),
        {
          status: function (row) {
            return <PageStatusBadge page={row} />
          },
        },
        {
          market: function (row) {
            return row.market.label
          },
        }
      )
    },
    modelSelectCustomColumns() {
      return [
        { field: 'region_code', type: 'text', label: 'Region code' },
        Object.assign({}, BasicOptions.newActiveColumn('page_hreflang_group_id'), {
          template: TableColumnTemplate.BOOLEAN,
          label: 'Hreflang grouped',
        }),
      ]
    },
    modelSelectColumnTemplates() {
      return {
        market: function (row) {
          return row.market ? row.market.label : ''
        },
      }
    },
    rowClick(event, row) {
      window.open(`/site-pages/${row.id}`, '_blank')
    },
  },
}
</script>

<style lang="scss">
#seo-page-groups-body {
  .card-body {
    padding-top: 0;
  }
  #base-list {
    border-top: 0;
  }
}
</style>
