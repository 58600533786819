<template>
  <div>
    <data-table
      model="Translation"
      service-file="TranslationsService"
      path="hercules/translations/translations?with=language,site"
      ref="list"
      :columns="columns()"
      :rowActionButtons="rowActions"
      :exportAllRows="false"
      :column-templates="columnTemplates()"
      :customActionButton="{
        action: () => (showCsvTranslationModal = true),
        class: 'btn btn-warning',
        title: 'CSV Upload',
      }"
    />
    <translation-modal v-if="showCsvTranslationModal" @refresh="refresh" />
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'
import TranslationModal from '@molecules/cms/translations/csv-modal.vue'

export default {
  created() {
    this.setTitle()
  },
  data() {
    return {
      title: `Translations`,
      showCsvTranslationModal: false,
      rowActions: [
        {
          action: this.typesAction,
          title: 'Clone',
          icon: 'uil uil-map',
          class: 'btn-info',
        },
      ],
    }
  },
  components: { TranslationModal },
  methods: {
    columns() {
      return [
        { field: 'language', type: 'text', filterable: true },
        { field: 'key', type: 'text', filterable: true },
        { field: 'value', type: 'text', template: TableColumnTemplate.TEXT_TRUNCATE, filterable: true },
        {
          field: 'site_id',
          label: 'Site',
          filterable: true,
          path: 'hercules/globals/sites?orderBy=name&fields_only[]=id&fields_only[]=name&ascending=1',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          fetch_on_load: false,
          clearFilter: true,
        },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    refresh() {
      this.$refs.list.search()
    },
    typesAction(row) {
      this.$refs.list.$refs.createModal.show()
      this.$refs.list.$refs.createModal.object = {
        language_id: row.language_id,
        key: row.key,
        value: row.value,
      }
    },
    columnTemplates() {
      return Object.assign({
        language: function (row) {
          return row.language && row.language.code
        },
        site_id: function (row) {
          return row.site?.name
        },
      })
    },
  },
}
</script>
