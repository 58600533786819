<template>
  <div class="modal" ref="quickAddmodal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Quick Add {{ $prettyLabels(labelOf) }}</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body pb-2" v-if="structure && config">
          <dynamic-form
            ref="quickAddForm"
            form-type="create"
            :formFields="structure.list"
            :structure="structure"
            :path="config.url"
            :object="object"
            :on-submit="submit"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import { loadService, loadModel } from '@mixins/HandleDynamicImports.js'

export default {
  components: {
    BaseInput,
    BaseSelect,
  },
  props: {
    labelOf: {
      type: String,
      required: true,
    },
    modelAndService: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      object: {},
      structure: null,
      config: null,
      siteId: null,
      marketId: null,
      service: null,
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  computed: {
    getFormObject() {
      return this.$store.state.dynamicForm.formObject
    },
    serviceLoader() {
      return () => loadService(this.modelAndService.service)
    },
    modelLoader() {
      return () => loadModel(this.modelAndService.model)
    },
  },
  methods: {
    async loadModelAndService() {
      if (this.modelAndService.model === 'Ribbons') {
        this.siteId = this.getFormObject?.affiliate_id
        this.marketId = this.getFormObject?.market_id
      }

      if (this.modelAndService) {
        try {
          const model = await this.modelLoader()
          this.config = model.default.getConfig()
          this.structure = model.default.getStructure()

          const service = await this.serviceLoader()
          this.service = service.default
        } catch (error) {
          console.error('Failed to load model or service:', error)
        }
      }
    },
    show() {
      this.object = {}
      this.structure = null
      this.config = null
      this.service = null

      this.loadModelAndService()
      this.$refs.quickAddmodal.classList.add('show')
    },
    close() {
      this.$refs.quickAddmodal && this.$refs.quickAddmodal.classList.remove('show')
    },
    async submit() {
      this.$refs.quickAddForm.isLoading = true
      if (this.modelAndService.model === 'Ribbons') {
        this.object.site_id = this.siteId
      }
      await this.service
        .post(this.object)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage(this.labelOf + ' created successfully')
            this.object = null
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => {
          if (this.$refs.quickAddForm) {
            this.$refs.quickAddForm.isLoading = false
          }
        })
    },
  },
}
</script>

<style scoped>
.modal-dialog {
  max-width: 960px !important;
  top: 30% !important;
}
.gap-4 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
}
@media (max-width: 768px) {
  .gap-4 {
    grid-template-columns: 1fr;
    gap: 0;
  }
}
</style>
