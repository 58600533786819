<template>
  <div>
    <data-table
      model="GameSiteData"
      service-file="AffiliateGameService"
      :serviceParams="[game_id]"
      :path="path()"
      :columns="columns()"
      :create-defaults="createDefaults()"
      :column-templates="columnTemplates()"
      :rowActionButtons="rowActions"
      :show-remove="false"
      ref="list"
    />
  </div>
</template>

<script lang="jsx">
import StatusColumn from '@/components/table/status-column.vue'
import TableColumnTemplate from '@constants/table-column-template.js'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import AffiliateGameService from '@services/AffiliateGameService.js'
import PagesService from '@services/PagesService.js'

const confirmDelete = async ({ title, content, htmlForPageRelations, actionButtonTitle }) => {
  return await openDialog(ConfirmDialog, { title, content, htmlForPageRelations, actionButtonTitle })
}
export default {
  created() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  data() {
    return {
      game_id: this.$route.params['id'],
      rowActions: [
        {
          action: this.remove,
          title: 'Delete',
          icon: 'uil uil-trash-alt',
          class: 'btn-danger',
        },
      ],
    }
  },
  mounted() {},
  methods: {
    columns() {
      return [
        { field: 'market', type: 'text', filterable: true },
        { field: 'site_name', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
        { field: 'status', type: 'text', filterable: true },
      ]
    },
    path() {
      return `hercules/games/affiliate-games?game_id=${this.$route.params['id']}&with=market,affiliate`
    },
    columnTemplates() {
      return Object.assign({
        market: function (row) {
          if (row.market) {
            return row.market.label
          }
        },
        status: function (row) {
          return <StatusColumn status={row.status} />
        },
      })
    },
    createDefaults() {
      return {
        game_id: this.$route.params['id'],
      }
    },
    async checkPageRelations(id) {
      try {
        const response = await PagesService.get({ relation_type: 'games', relation_id: id })
        if (response.data.result) {
          this.pageRelations = response.data.result
        } else {
          console.error('No page relations found or error in response:', response)
          this.pageRelations = []
        }
      } catch (error) {
        console.error('Error fetching page relations:', error)
        this.showErrorMessage('Error fetching page relations')
      }
    },
    async remove(row) {
      await this.checkPageRelations(row.id)

      try {
        let htmlForPageRelations = ''

        if (this.pageRelations.length > 0) {
          const relatedPageIds = this.pageRelations
            .map((item) => `- <a href='/site-pages/${item.id}' target="_blank"> ${item.title} </a>`)
            .join('<br>')

          htmlForPageRelations = `<p>This item is used as a page relation for the following pages:<br>${relatedPageIds}</p>`
        } else {
          htmlForPageRelations = `<p>This item is not used as a page relation for any existing pages. It is safe to delete.</p>`
        }

        const confirm = await confirmDelete({
          title: 'Warning',
          content: 'Are you sure you want to delete this item?',
          htmlForPageRelations: htmlForPageRelations,
          actionButtonTitle: 'Delete item',
        })

        if (confirm) {
          this.loading = true

          if (row.id) {
            const deleteResponse = await AffiliateGameService.remove({ id: row.id })

            if (deleteResponse.data?.messages) {
              this.showErrorMessages(deleteResponse.data.messages)
            } else {
              this.showSuccessMessage('Item removed successfully.')
              this.close()
            }
          }
        }
      } catch (error) {
        console.error('Error removing item:', error)
        this.showUnknownErrorMessage()
      } finally {
        this.loading = false
        this.refresh()
      }
    },
    refresh() {
      this.$refs.list.search()
    },
  },
}
</script>
