<template>
  <div class="card">
    <dynamic-form
      ref="form"
      form-type="edit"
      :inCard="true"
      :formFields="structure.widgetList"
      :path="config.url"
      :structure="structure"
      :object="object"
      :on-submit="submit"
    />
  </div>
</template>

<script>
import DynamicForm from '@/components/fields/DynamicForm.vue'
import Affiliate from '@models/Affiliate.js'
import AffiliatesService from '@services/AffiliatesService.js'

export default {
  data() {
    return {
      object: {},
      structure: { widgetList: [] },
      config: Affiliate.getConfig(),
    }
  },
  created() {
    this.getAffiliate()
  },
  methods: {
    getAffiliate() {
      AffiliatesService.getOne(this.$route.params['id']).then((response) => {
        this.object = response.data.result[0]
        this.structure = { widgetList: Affiliate.getWidgetSettingsFields(this.$route.params['id']) }
      })
    },
    submit() {
      const self = this
      this.$refs.form
        .process(this.object)
        .then((response) => {
          return AffiliatesService.update(response)
        })
        .then((response) => {
          if (response.data.messages) {
            self.showErrorMessages(response.data.messages)
          } else {
            self.showSuccessMessage(`${self.structure.config.name} updated`)
            self.$parent.$parent.getAffiliate()
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => (self.$refs.form.isLoading = false))
    },
  },
}
</script>
