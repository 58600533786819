<template>
  <div class="card">
    <div class="card-body">
      <dynamic-form
        ref="form"
        form-type="edit"
        :formFields="structure.list"
        :structure="structure"
        :path="config.url"
        :object="object"
        :on-submit="submit"
        :show-remove="false"
      />
    </div>
  </div>
</template>

<script>
import DynamicForm from '@/components/fields/DynamicForm.vue'
import SiteProvider from '@models/sport/SiteProvider.js'
import SiteProvidersService from '@services/SiteProvidersService.js'

export default {
  components: { DynamicForm },
  data() {
    return {
      object: {},
      structure: { list: [] },
      config: SiteProvider.getConfig(),
    }
  },
  mounted() {
    this.getOperator()
    this.setTitle(`${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} Site data`)
  },
  methods: {
    getOperator() {
      SiteProvidersService.getOne(this.$route.params['id']).then((response) => {
        this.object = response.data.result[0]
        this.getStructure()
      })
    },
    getStructure() {
      this.structure = SiteProvider.getStructure()
    },
    submit() {
      const self = this
      this.$refs.form
        .process(this.object)
        .then((response) => {
          return SiteProvidersService.update(response)
        })
        .then((response) => {
          if (response.data.messages) {
            self.showErrorMessages(response.data.messages)
          } else {
            self.showSuccessMessage(`${self.structure.config.name} updated`)
            self.$parent.$parent.getSiteProvider()
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
  },
}
</script>
