<template>
  <div
    class="flex-table-row border rounded border-light"
    :class="[
      row.added && 'bg-green-1aa0',
      (row.removed || (affiliateOperator && ['inactive', 'restricted'].includes(affiliateOperator.status))) &&
        'row-removed',
    ]"
  >
    <div class="toplist-item-position" v-if="canChangePosition">
      <button
        class="btn btn-sm btn-secondary icon-button"
        :class="{
          disabled:
            row.removed ||
            (affiliateOperator && ['inactive', 'restricted'].includes(affiliateOperator.status)),
        }"
        @click="$emit('openPositionModal', row, realIndex)"
      >
        <i class="uil uil-arrows-resize-v"></i>
      </button>
      <span class="badge counter-badge text-black" :class="badgeClass(realIndex)">{{
        realIndex ? realIndex : '-'
      }}</span>
    </div>
    <div class="toplist-item-operator-name operator-name">
      <a class="" :href="`/operators/${row.operator.id}`" target="_blank">{{ row.operator.name }}</a>
      <div
        v-if="affiliateOperator && affiliateOperator.status !== 'active'"
        class="text-truncate"
        :class="statusClass"
      >
        {{ $capitalize(statusName) }}
      </div>
    </div>
    <div class="toplist-item-rating">
      <span
        class="rating-text ribon-text-more cursor-pointer"
        v-if="affiliateOperator && affiliateOperator.rating !== ''"
        @click="openRatingModal"
        >{{ formatRating(affiliateOperator.average_rating || affiliateOperator.rating) }} / 5</span
      >
      <button v-else class="btn btn-sm btn-secondary" @click="openRatingModal">
        <i class="uil uil-plus text-gray" />Rating
      </button>
      <item-rating-modal
        v-if="showRatingModal"
        ref="addModal"
        :index="index"
        :row="row"
        :list="list"
        :storeName="storeName"
      />
    </div>
    <div class="toplist-item-bonus-tracker" v-if="!isPrefilledTemplate">
      <item-column-bonus
        v-if="affiliateOperator"
        :bonus="bonus"
        :row="row"
        :toplist="list"
        :affiliateOperator="affiliateOperator"
        :showFullBonus="showFullBonus"
        @updateBonus="(bonus) => $emit('updateBonus', row, bonus)"
      />
      <item-column-tracker
        v-if="affiliateOperator"
        :row="row"
        :tracker="tracker"
        :toplist="list"
        :affiliateOperator="affiliateOperator"
        :showFullTracker="showFullTracker"
        @updateTracker="(id) => $emit('updateTracker', row, id)"
      />

      <div class="toplist-item-sites-data-missing" v-if="affiliateOperator === null">
        <div class="badge bg-orange-75 font-weight-normal w-100" v-if="isLoading">
          <i class="uil uil-spin" />Creating sites data..
        </div>
        <div class="badge bg-orange font-weight-normal clickable w-100" v-else @click="createSitesData">
          <i class="uil uil-exclamation-triangle" />Click to create
        </div>
      </div>
    </div>
    <div class="toplist-item-affiliate-platform" v-if="showAffiliatePlatform">
      <div v-if="affiliatePlatform">
        <div class="badge badge-mint">
          <i class="uil uil-check"></i>
          <span class="badge-text">{{ showFullAffiliatePlatform ? affiliatePlatform : 'Set' }}</span>
        </div>
      </div>
      <div v-else>
        <div class="badge badge-danger clickable" @click="openPlatformModal">
          <i class="uil uil-times"></i> <span class="badge-text">Missing</span>
        </div>
      </div>
      <affiliate-platform-domain-modal
        v-if="showPlatformDomainModal"
        ref="platformDomainModal"
        @refresh="(...args) => $emit('domainUpdated', ...args)"
        :index="index"
        :row="row"
      />
    </div>
    <div class="toplist-item-ribbons" v-if="!isPrefilledTemplate">
      <div
        class="ribbon-text text-truncate clickable text-truncate"
        v-if="row.ribbons.length !== 0"
        @click="openRibbonModal"
      >
        {{ row.ribbons[0].label }}
      </div>

      <div
        class="ribbon-text ribbon-text-more"
        v-if="row.ribbons.length > 1"
        :data-tooltip="ribbonTooltip"
        data-tooltip-position="top left"
      >
        + {{ row.ribbons.length - 1 }}
      </div>
      <button v-if="row.ribbons.length === 0" class="btn btn-sm btn-secondary" @click="openRibbonModal">
        <i class="uil uil-plus text-blue" />Ribbon
      </button>
      <item-ribbon-modal
        v-if="showRibbonModal"
        ref="addModal"
        :index="index"
        :row="row"
        :list="list"
        :storeName="storeName"
      />
    </div>
    <div class="toplist-item-selling-points" v-if="!isPrefilledTemplate && list.enable_selling_points">
      <div
        class="selling-points-text text-truncate clickable text-truncate"
        v-if="row.selling_points.length !== 0"
        @click="openSellingPointsModal"
      >
        {{ row.selling_points[0].name }}
      </div>

      <div
        class="selling-points-text selling-points-text-more"
        v-if="row.selling_points.length > 1"
        :data-tooltip="sellingPointsTooltip"
        data-tooltip-position="top left"
      >
        + {{ row.selling_points.length - 1 }}
      </div>
      <button
        v-if="row.selling_points.length === 0"
        class="btn btn-sm btn-secondary"
        @click="openSellingPointsModal"
      >
        <i class="uil uil-plus text-blue" />Selling point
      </button>
      <item-selling-points-modal
        v-if="showSellingPointsModal"
        ref="addModal"
        :index="index"
        :row="row"
        :list="list"
        :storeName="storeName"
      />
    </div>
    <div class="toplist-item-actions">
      <template v-if="row.removed">
        <span class="text-red font-size-sm pointer-events-none"> Removed </span>
        <button class="btn icon-btn px-1" @click="toggleSoftDelete">
          <i class="uil uil-redo text-black-50" />
        </button>
      </template>
      <template v-else-if="row.added === true">
        <span class="text-normal-green font-size-sm pointer-events-none"> Added </span>
        <button class="btn icon-btn px-1" @click="toggleSoftDelete">
          <i class="uil uil-trash-alt text-black-50" />
        </button>
      </template>
      <template v-else>
        <button class="btn icon-btn px-1" @click="toggleSoftDelete">
          <i class="uil uil-trash-alt text-muted clickable text-black-50" />
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import ItemColumnTracker from './item-colum-tracker.vue'
import ItemColumnBonus from './item-colum-bonus.vue'
import { cloneDeep, has } from 'lodash'
import ItemRibbonModal from './item-ribbon-modal.vue'
import AffiliatePlatformDomainModal from './platform-domain-modal.vue'
import ItemRatingModal from './item-rating-modal.vue'
import ItemSellingPointsModal from '@organisms/dms/toplist/item-selling-points-modal.vue'

export default {
  components: {
    ItemSellingPointsModal,
    ItemColumnTracker,
    ItemColumnBonus,
    ItemRibbonModal,
    AffiliatePlatformDomainModal,
    ItemRatingModal,
  },
  data: function () {
    return {
      isLoading: false,
      showBonusEditModal: false,
      showLinkEditModal: false,
      showRibbonModal: false,
      showPlatformDomainModal: false,
      showRatingModal: false,
      showSellingPointsModal: false,
      hover: false,
    }
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    list: {
      type: Object,
      required: false,
    },
    storeName: {
      type: String,
      required: true,
    },
    index: {},
    realIndex: {},
    showFullBonus: {},
    showAffiliatePlatform: {},
    showFullTracker: {},
    showFullAffiliatePlatform: {},
    canChangePosition: {
      default: true,
    },
  },
  methods: {
    formatRating(rating) {
      return Number(rating).toFixed(1)
    },
    badgeClass(index) {
      if (
        this.row.removed ||
        (this.affiliateOperator && ['inactive', 'restricted'].includes(this.affiliateOperator.status))
      ) {
        return ''
      }
      switch (index) {
        case 1:
          return 'bg-gold'
        case 2:
          return 'bg-silver'
        case 3:
          return 'bg-bronze'
        default:
          return 'bg-blue-10'
      }
    },
    async createSitesData() {
      this.isLoading = true
      await this.$http
        .post('hercules/operators/affiliate-operators', {
          affiliate_id: this.list.affiliate_id,
          market_id: this.list.market_id,
          type: this.list.operator_type,
          operator_id: this.row.operator_id,
          rating: 5,
        })
        .then((response) => {
          if (response.data.success) {
            response.data.result.types = []
            response.data.result.links = []
            this.$store.commit(`${this.storeName}/createSitesData`, {
              result: [response.data.result],
              item: this.row,
            })
            this.showSuccessMessage('Sites data created')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    toggleSoftDelete() {
      let clone = cloneDeep(this.row)
      clone.removed = !clone.removed
      this.$store.dispatch(`${this.storeName}/updateItem`, { index: this.index, item: clone })
    },
    openRibbonModal() {
      this.showRibbonModal = true
      this.$nextTick(() => {
        this.$refs.addModal.show()
      })
    },
    openRatingModal() {
      this.showRatingModal = true
      this.$nextTick(() => {
        this.$refs?.addModal.show()
      })
    },
    openSellingPointsModal() {
      this.showSellingPointsModal = true
      this.$nextTick(() => {
        this.$refs.addModal.show()
      })
    },
    openPlatformModal() {
      this.showPlatformDomainModal = true
      this.$nextTick(() => {
        this.$refs.platformDomainModal.show(this.row, this.tracker.link)
      })
    },
  },
  computed: {
    statusName() {
      return this.affiliateOperator ? this.affiliateOperator.status.replace('_', ' ') : ''
    },
    statusClass() {
      return this.affiliateOperator
        ? `operator-status-badge operator-status-${this.affiliateOperator.status.replace('_', '-')}`
        : ''
    },
    affiliateOperator() {
      if (!this.row.operator.affiliate_operators) {
        return null
      }
      return this.row.operator.affiliate_operators[0] || null
    },
    affiliatePlatform() {
      if (has(this.row.operator, 'affiliate_operators[0].links[0].affiliate_platform_domain.platform')) {
        return this.row.operator.affiliate_operators[0].links[0].affiliate_platform_domain.platform.name
      }
      return null
    },
    tracker() {
      if (this.affiliateOperator === null) {
        return null
      }
      return this.affiliateOperator.links[0] || null
    },
    bonus() {
      if (this.affiliateOperator === null) {
        return null
      }
      return this.affiliateOperator.types[0] || null
    },
    ribbonTooltip() {
      return this.row.ribbons
        .slice(1)
        .map((item) => item.label)
        .join(', ')
    },
    sellingPointsTooltip() {
      return this.row.selling_points
        .slice(1)
        .map((item) => item.label)
        .join(', ')
    },
    isPrefilledTemplate() {
      return this.list.list_type === 'prefilled_template'
    },
  },
}
</script>

<style lang="scss">
.flex-table {
  display: flex;
  flex-direction: column;
}
.flex-table-row {
  display: flex;
  gap: 18px;
  min-height: 50px;
  align-items: center;
  padding: 0 14px;
  margin: 6px 0;
}
.flex-table-body .flex-table-row {
  &:hover {
    background: #fbfbff;
  }
}

.items-container {
  width: 100%;
}

.scheduling-active {
  .items-container {
    width: 50%;
  }
  .badge-text {
    display: none;
  }
  .toplist-item-operator-name {
    min-width: 140px;
  }
  .flex-table-row {
    gap: 14px;
  }
  .toplist-item-bonus-tracker {
    width: 140px;
  }
  .toplist-item-bonus-tracker-actions {
    display: none;
  }
  .toplist-item-selling-points {
    width: 100px;
  }
}

.toplist-item-position {
  width: 80px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
}
.toplist-item-operator-name {
  flex: 1;
  display: flex;
  gap: 10px;
  min-width: 100px;
}

.toplist-item-affiliate-platform {
  display: flex;
  width: 150px;
  .badge {
    font-weight: normal;
    max-width: 130px;
  }
}

.toplist-item-bonus-tracker {
  display: flex;
  width: 430px;
  .toplist-item-bonus,
  .toplist-item-tracker {
    flex: 1;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .badge {
      font-weight: normal;
    }
  }
  @media screen and (min-width: 768px) {
    .toplist-item-bonus,
    .toplist-item-tracker {
      margin-right: 20px;
    }
  }
}
.toplist-item-status {
  flex: 1;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toplist-item-ribbons {
  display: flex;
  justify-content: center;
  gap: 6px;
  width: 140px;
  .ribbon-text {
    font-size: 12px;
    border-radius: 10px;
    align-content: center;
    justify-content: center;
    background-color: #e3e6ef;
    align-self: center;
    padding: 4px 10px;
  }
  .ribbon-text-more {
    flex-shrink: 0;
    padding: 4px 6px;
    font-size: 10px;
    background-color: #eff1f6;
    overflow: visible !important;
  }
}
.toplist-item-rating {
  display: flex;
  gap: 6px;
  width: 140px;
  justify-content: center;
  .rating-text {
    font-size: 12px;
    border-radius: 10px;
    align-content: center;
    justify-content: center;
    background-color: #e3e6ef;
    align-self: center;
    padding: 4px 10px;
  }
  .rating-text-more {
    flex-shrink: 0;
    padding: 4px 6px;
    font-size: 10px;
    background-color: #eff1f6;
    overflow: visible !important;
  }
}
.toplist-item-selling-points {
  display: flex;
  gap: 6px;
  width: 140px;
  .selling-points-text {
    font-size: 12px;
    border-radius: 10px;
    align-content: center;
    justify-content: center;
    background-color: #e3e6ef;
    align-self: center;
    padding: 4px 10px;
  }
  .selling-points-text-more {
    flex-shrink: 0;
    padding: 4px 6px;
    font-size: 10px;
    background-color: #eff1f6;
    overflow: visible !important;
  }
}
.toplist-item-sites-data-missing {
  display: flex;
  flex: 1;
}
.toplist-item-actions {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100px;
  gap: 3px;
}
</style>
