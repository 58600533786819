<template>
  <div v-if="editor" class="button-wrapper p-2">
    <div class="button-group">
      <base-select
        v-if="!hiddenTools.includes('heading')"
        id="TextFormat"
        :loadOnMount="false"
        optionLabelKey="name"
        ref="baseSelect"
        v-model="selectHeading"
        :customOptions="headings"
        placeholder="Select heading"
        :class="
          'mb-0 w-20 tiptap-base-select pr-1 mb-1 border-right-light highlighted-option-' + selectHeading
        "
        :allowNullOption="true"
        :removeActiveText="true"
        :disabled="toggleDisableTableButton"
      />
      <base-select
        id="PanelType"
        :loadOnMount="false"
        optionLabelKey="name"
        ref="panelSelect"
        v-model="selectPanelType"
        :customOptions="panelTypes"
        placeholder="Select panel type"
        :class="
          'mb-0 w-20 tiptap-base-select pr-1 mb-1 border-right-light highlighted-option-' + selectPanelType
        "
        :allowNullOption="true"
        :removeActiveText="true"
        :disabled="disablePanels || toggleDisableTableButton"
      />
      <div
        class="d-flex justify-content-center align-items-center pr-1 mb-1 border-right-light"
        v-if="!hiddenTools.includes('FullScreenToggle')"
      >
        <popper hover placement="top" arrow>
          <template #content>
            <span class="popper">Toggle Fullscreen</span>
          </template>
          <button id="FullscreenToggle" @click.stop.prevent="toggleFullscreen" slot="reference">
            <i class="uil uil-expand-arrows"></i>
          </button>
        </popper>
      </div>
      <div class="d-flex justify-content-center align-items-center pr-1 mb-1 border-right-light">
        <popper hover placement="top" arrow v-if="!hiddenTools.includes('bold')">
          <template #content>
            <span class="popper">{{ `Bold ${isMac ? '⌘' : 'Ctrl + '}B` }}</span>
          </template>
          <button
            @click.stop.prevent="editor.chain().focus().toggleBold().run()"
            slot="reference"
            :disabled="textActionsDisabled || showSource"
            :class="[editor.isActive('bold') && 'is-active']"
            id="Bold"
          >
            <i class="uil uil-bold"></i>
          </button>
        </popper>
        <popper hover placement="top" arrow v-if="!hiddenTools.includes('italic')">
          <template #content>
            <span class="popper">{{ `Italic ${isMac ? '⌘' : 'Ctrl + '}I` }}</span>
          </template>
          <button
            id="Italic"
            @click.stop.prevent="editor.chain().focus().toggleItalic().run()"
            slot="reference"
            :disabled="textActionsDisabled || showSource"
            :class="{ 'is-active': editor.isActive('italic') }"
          >
            <i class="uil uil-italic"></i>
          </button>
        </popper>
        <div class="d-flex align-items-center popover-wrapper-custom">
          <popper placement="bottom" v-if="!hiddenTools.includes('textEmphasis')">
            <template #content>
              <div class="textEmphasisOptions">
                <span
                  v-for="(option, tKey) in textEmphasisOptions"
                  :key="tKey"
                  :id="option.name"
                  @click="selectTextEmphasis(option.id === 6 && clickedOnBlockquote ? null : option.id)"
                  class="d-flex align-items-center justify-content-between py-1 m-0 alignment-btn"
                  :class="{
                    'action-disabled': option.id === 6 && clickedOnBlockquote,
                    active: editor.isActive(option.tiptapName),
                  }"
                >
                  <span>{{ option.name }}</span>
                  <span class="textEmphasisOptions--option__icon"> {{ option.icon }}</span>
                </span>
              </div>
            </template>

            <span class="btn-popper cursor-pointer" open:popper :disabled="showSource" slot="reference">
              <i class="uil uil-ellipsis-h"></i>
            </span>
          </popper>
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center pr-1 mb-1 border-right-light"
        v-click-outside-popover-alignment="hideAlignText"
        v-if="!hiddenTools.includes('textAlignment')"
      >
        <div class="popover-wrapper d-flex align-items-center">
          <popper hover placement="top" arrow>
            <template #content>
              <span class="popper text-overflow">Text Alignment</span>
            </template>
            <button
              id="TextAlignment"
              @click.stop.prevent="showAlignText"
              slot="reference"
              :disabled="showSource"
            >
              <i :class="alignIcon"></i>
            </button>
          </popper>

          <span
            v-if="showAlignOptions"
            class="d-flex align-items-center justify-content-between popover-content-padding text-alignment-options"
          >
            <div
              v-for="(alignment, key) in alignments"
              :key="key"
              class="alignment-btn"
              @click="alignItem(alignment.value)"
            >
              <popper hover placement="top" arrow>
                <template #content>
                  <span class="popper">{{ 'Align ' + alignment.value }}</span>
                </template>
                <i :id="'Align' + alignment.value" :class="alignment.icon" slot="reference"></i>
              </popper>
            </div>
          </span>
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center pr-1 mb-1 border-right-light"
        v-if="!hiddenTools.includes('table')"
      >
        <popper hover placement="top" arrow>
          <template #content>
            <span class="popper">Add Table</span>
          </template>
          <button
            @click.stop.prevent="addTable"
            :disabled="toggleDisableTableButton || clickedOnBlockquote || showSource"
            slot="reference"
            id="AddTable"
          >
            <i class="uil uil-table"></i>
          </button>
        </popper>
        <div class="baseEditorTable">
          <base-editor-table-actions
            class="baseEditorTable--popup position-absolute"
            v-if="showTableActions"
            :editor="editor"
            @hideTableActions="hideTableActions"
          />
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center pr-1 mb-1 border-right-light"
        v-if="!hiddenTools.includes('lists')"
      >
        <popper hover placement="top" arrow>
          <template #content>
            <span class="popper">{{ `Ordered List ${isMac ? '⌘⇧' : 'Ctrl + ⇧ + '}7` }}</span>
          </template>
          <button
            id="OrderedList"
            @click.stop.prevent="setOrderedList"
            :class="{ 'is-active': editor.isActive('orderedList') }"
            slot="reference"
            :disabled="clickedOnBlockquote || showSource || toggleDisableTableButton"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-list-ol"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"
              />
              <path
                d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z"
              />
            </svg>
          </button>
        </popper>
        <popper hover placement="top" arrow>
          <template #content>
            <span class="popper">{{ `Unordered List ${isMac ? '⌘⇧' : 'Ctrl + ⇧ + '}8` }}</span>
          </template>
          <button
            id="UnorderedList"
            @click.stop.prevent="setUnorderedList"
            :class="{ 'is-active': editor.isActive('bulletList') }"
            slot="reference"
            :disabled="clickedOnBlockquote || showSource || toggleDisableTableButton"
          >
            <i class="uil uil-list-ul"></i>
          </button>
        </popper>
      </div>
      <div class="d-flex justify-content-center align-items-center pr-1 mb-1 border-right-light">
        <div v-if="!hiddenTools.includes('textColor')">
          <popper hover placement="top" arrow>
            <template #content>
              <span class="popper">Text Color</span>
            </template>
            <button
              :disabled="textActionsDisabled || showSource"
              @click.stop.prevent="colorPickerText"
              :class="{ 'is-active': editor.isActive('color') }"
              id="colorPickerText"
              slot="reference"
            >
              <i class="uil uil-palette"></i>
            </button>
          </popper>
          <base-colorpicker
            v-if="showTextColorPicker"
            class="position-absolute"
            :editor="editor"
            @setColor="setTextColor"
            @unsetColor="unsetTextColor"
            @hideColorPicker="hideColorPicker"
            :inputHex="editor.getAttributes('textStyle').color || '#000000'"
          />
        </div>
        <div v-if="!hiddenTools.includes('backgroundColor')">
          <popper hover placement="top" arrow>
            <template #content>
              <span class="popper">Text Background Color</span>
            </template>
            <button
              :disabled="textActionsDisabled || showSource"
              @click.stop.prevent="colorPickerBackground"
              :class="{ 'is-active': editor.isActive('color') }"
              id="colorPickerBackground"
              slot="reference"
            >
              <i class="uil uil-paint-tool"></i>
            </button>
          </popper>
          <base-colorpicker
            class="position-absolute"
            :editor="editor"
            v-if="showBackgroundColorPicker"
            @setColor="setBackgroundColor"
            @unsetColor="unsetBackgroundColor"
            @hideColorPicker="hideColorPicker"
            :inputHex="editor.getAttributes('highlight').color || '#ffffff'"
          />
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center pr-1 mb-1 border-right-light"
        v-if="!hiddenTools.includes('image')"
      >
        <popper hover placement="top" arrow>
          <template #content>
            <span class="popper">{{ `Add Image ${isMac ? '⌘⌥' : 'Ctrl + Alt + '}A` }}</span>
          </template>
          <button
            id="AddImage"
            @click.stop.prevent="$emit('setImage')"
            :disabled="imageActionsDisabled || clickedOnBlockquote || showSource"
            slot="reference"
          >
            <i class="uil uil-image"></i>
          </button>
        </popper>
      </div>
      <div
        class="d-flex justify-content-center align-items-center pr-1 mb-1 border-right-light"
        v-if="!hiddenTools.includes('link') || !hiddenTools.includes('anchor')"
      >
        <popper hover placement="top" arrow>
          <template #content>
            <span class="popper">{{ `Link ${isMac ? '⌘' : 'Ctrl + '}K` }}</span>
          </template>
          <button
            id="AddLink"
            @click.stop.prevent="$emit('setLink')"
            :class="{
              'is-active': (selectedText && editor.isActive('link')) || editor.getAttributes('figure').href,
            }"
            :disabled="clickedOnBlockquote || showSource"
            slot="reference"
          >
            <i class="uil uil-link-h"></i>
          </button>
        </popper>
        <popper hover placement="top" arrow v-if="!hiddenTools.includes('anchor')">
          <template #content>
            <span class="popper">Anchor</span>
          </template>
          <button
            id="AddAnchor"
            @click.stop.prevent="$emit('setAnchor')"
            :disabled="disableAnchor || showSource || toggleDisableTableButton"
            :class="{ 'is-active': isAnchor }"
            slot="reference"
          >
            <i class="uil uil-anchor"></i>
          </button>
        </popper>
      </div>
      <div
        class="d-flex justify-content-center align-items-center pr-1 mb-1 border-right-light"
        v-if="!hiddenTools.includes('columns')"
      >
        <popper hover placement="top" arrow>
          <template #content>
            <span class="popper">Layout Options</span>
          </template>
          <button
            id="LayoutOptions"
            @click.stop.prevent="showLayoutOptions"
            :class="{ 'is-active': editor.isActive('columns') }"
            :disabled="textActionsDisabled || showSource || disableTableAndLayout"
            slot="reference"
          >
            <i class="uil uil-columns"></i>
          </button>
        </popper>

        <span
          v-if="showLayoutModal"
          class="d-flex align-items-center justify-content-between popover-content-padding layout-options"
        >
          <div
            v-for="(layout, key) in layoutOptions"
            :key="key"
            class="layout-btn"
            @click="applyLayout(layout.value)"
          >
            <popper hover placement="top" arrow>
              <template #content>
                <span class="popper">{{ layout.label }}</span>
              </template>
              <i :id="'Layout' + layout.value" :class="layout.icon" slot="reference"></i>
            </popper>
          </div>
        </span>
      </div>
      <div class="d-flex justify-content-center align-items-center pr-1 mb-1 border-right-light">
        <popper hover placement="top" arrow v-if="!hiddenTools.includes('blockQuote')">
          <template #content>
            <span class="popper">{{ `Blockquote ${isMac ? '⌘⇧' : 'Ctrl + ⇧ + '}B` }}</span>
          </template>
          <button
            id="Blockquote"
            @click.stop.prevent="editor.chain().focus().toggleBlockquote().run()"
            :disabled="showSource || !editor.can().setBlockquote()"
            slot="reference"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              :fill="showSource || !editor.can().setBlockquote() ? '#d3d3d3' : ''"
            >
              <path
                d="M9.983 3v7.391c0 5.704-3.731 9.57-8.983 10.609l-.995-2.151c2.432-.917 3.995-3.638 3.995-5.849h-4v-10h9.983zm14.017 0v7.391c0 5.704-3.748 9.571-9 10.609l-.996-2.151c2.433-.917 3.996-3.638 3.996-5.849h-3.983v-10h9.983z"
              />
            </svg>
          </button>
        </popper>
        <popper hover placement="top" arrow v-if="!hiddenTools.includes('horizontalRule')">
          <template #content>
            <span class="popper">Horizontal Line</span>
          </template>
          <button
            id="HorizontalLine"
            @click.stop.prevent="setHorizontalRule"
            slot="reference"
            :disabled="showSource"
          >
            <strong>&#8213;</strong>
          </button>
        </popper>
      </div>
      <popper hover placement="top" arrow>
        <template #content>
          <span class="popper">{{ `Undo ${isMac ? '⌘' : 'Ctrl + '}Z` }}</span>
        </template>
        <button
          id="Undo"
          @click.stop.prevent="editor.chain().focus().undo().run()"
          :disabled="!editor.can().chain().focus().undo().run() || showSource"
          slot="reference"
        >
          <i class="uil uil-redo" style="transform: rotate(-180deg)"></i>
        </button>
      </popper>
      <popper hover placement="top" arrow>
        <template #content>
          <span class="popper">{{ `Redo ${isMac ? '⌘⇧' : 'Ctrl + ⇧ + '}Z` }}</span>
        </template>
        <button
          id="Redo"
          @click.stop.prevent="editor.chain().focus().redo().run()"
          :disabled="!editor.can().chain().focus().redo().run() || showSource"
          slot="reference"
        >
          <i class="uil uil-redo"></i>
        </button>
      </popper>
      <popper hover placement="top" arrow v-if="!hiddenTools.includes('sourceCode')">
        <template #content>
          <span class="popper">Code View</span>
        </template>
        <button id="CodeView" @click.stop.prevent="$emit('toggleSource')" slot="reference">
          <i class="uil uil-arrow"></i>
        </button>
      </popper>
    </div>
  </div>
</template>
<script>
import BaseSelect from '@/components/fields/base-select.vue'
import BaseColorpicker from './base-colorpicker.vue'
import BaseEditorTableActions from './base-editor-table-actions.vue'
import CustomFullScreen from './customFullScreen.js'
import CustomLayout from './customLayout.js'
export default {
  mixins: [CustomFullScreen, CustomLayout],
  directives: {
    'click-outside-popover-alignment': {
      mounted: function (el, binding) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    },
  },

  components: {
    BaseSelect,
    BaseColorpicker,
    BaseEditorTableActions,
  },
  data() {
    return {
      panelTypes: [
        { id: 'info', name: 'Info' },
        { id: 'success', name: 'Success' },
        { id: 'error', name: 'Error' },
        { id: 'warning', name: 'Warning' },
      ],
      showPanelDropdown: false,
      showAlignOptions: false,
      disableTableActions: false,
      showTableActions: false,
      showSelect: false,
      baseButtonClass: 'btn btn-sm btn-secondary',
      alignments: [
        {
          id: 0,
          value: 'left',
          icon: 'uil uil-align-left',
          // shortcut: `${navigator.userAgent.indexOf('Mac') != -1 ? '⌘⇧' : 'Ctrl + ⇧ + '}L`
        },
        {
          id: 1,
          value: 'center',
          icon: 'uil uil-align-center',
          // shortcut: `${navigator.userAgent.indexOf('Mac') != -1 ? '⌘⇧' : 'Ctrl + ⇧ + '}E`
        },
        {
          id: 2,
          value: 'right',
          icon: 'uil uil-align-right',
          // shortcut: `${navigator.userAgent.indexOf('Mac') != -1 ? '⌘⇧' : 'Ctrl + ⇧ + '}R`
        },
        {
          id: 3,
          value: 'justify',
          icon: 'uil uil-align-justify',
          // shortcut: `${navigator.userAgent.indexOf('Mac') != -1 ? '⌘⇧' : 'Ctrl + ⇧ + '}J`
        },
      ],
      alignment: 'uil uil-align-left',
      headings: [
        {
          name: 'Normal Text',
          customClass: 'text-dark',
          id: 0,
        },
        {
          name: 'Heading 2',
          customClass: `heading-shortcut-icon-${2} ${
            navigator.userAgent.indexOf('Mac') != -1 ? 'isMac' : ''
          }`,
          id: 2,
        },
        {
          name: 'Heading 3',
          customClass: `heading-shortcut-icon-${3} ${
            navigator.userAgent.indexOf('Mac') != -1 ? 'isMac' : ''
          }`,
          id: 3,
        },
        {
          name: 'Heading 4',
          customClass: `heading-shortcut-icon-${4} ${
            navigator.userAgent.indexOf('Mac') != -1 ? 'isMac' : ''
          }`,
          id: 4,
        },
        {
          name: 'Heading 5',
          customClass: `heading-shortcut-icon-${5} ${
            navigator.userAgent.indexOf('Mac') != -1 ? 'isMac' : ''
          }`,
          id: 5,
        },
        {
          name: 'Heading 6',
          customClass: `heading-shortcut-icon-${6} ${
            navigator.userAgent.indexOf('Mac') != -1 ? 'isMac' : ''
          }`,
          id: 6,
        },
      ],
      textEmphasisOptions: [
        {
          name: 'Underline',
          tiptapName: 'underline',
          icon: `${navigator.userAgent.indexOf('Mac') != -1 ? '⌘' : 'Ctrl + '}U`,
          id: 1,
        },
        {
          name: 'Strikethrough',
          tiptapName: 'strike',
          icon: `${navigator.userAgent.indexOf('Mac') != -1 ? '⌘⇧' : 'Ctrl + ⇧ + '}S`,
          id: 2,
        },
        {
          name: 'Superscript',
          tiptapName: 'superscript',
          icon: `${navigator.userAgent.indexOf('Mac') != -1 ? '⌘⇧' : 'Ctrl + ⇧ + '}.`,
          id: 3,
        },
        {
          name: 'Subscript',
          tiptapName: 'subscript',
          icon: `${navigator.userAgent.indexOf('Mac') != -1 ? '⌘⇧' : 'Ctrl + ⇧ + '},`,
          id: 4,
        },
        {
          name: 'Code',
          tiptapName: 'code',
          icon: `${navigator.userAgent.indexOf('Mac') != -1 ? '⌘' : 'Ctrl + '}E`,
          id: 5,
        },
        {
          name: 'Clear Format',
          icon: `${navigator.userAgent.indexOf('Mac') != -1 ? '⌘' : 'Ctrl + '}⇧\\`,
          tiptapName: 'no-option',
          id: 6,
        },
        {
          name: 'LTR',
          icon: `${navigator.userAgent.indexOf('Mac') != -1 ? '⌘⌥' : 'Ctrl + Alt + '}L`,
          tiptapName: 'no-option',
          id: 7,
        },
        {
          name: 'RTL',
          icon: `${navigator.userAgent.indexOf('Mac') != -1 ? '⌘⌥' : 'Ctrl + Alt + '}R`,
          tiptapName: 'textDirection',
          id: 8,
        },
        {
          name: 'Break',
          tiptapName: 'no-option',
          icon: `${navigator.userAgent.indexOf('Mac') != -1 ? '⇧↵' : '⇧ + ↵'}`,
          id: 9,
        },
      ],
      selectedColor: null,
      showTextColorPicker: false,
      showBackgroundColorPicker: false,
    }
  },
  props: {
    hiddenTools: {
      type: Array,
      default: () => [],
    },
    editor: {},
    sourceVisible: {},
    required: true,
    toggleDisableTableButton: {
      type: Boolean,
      default: false,
    },
    showSource: {
      type: Boolean,
      default: false,
    },
    isMac: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    applyPanelType(id) {
      if (this.editor) {
        const { state, view } = this.editor
        const { selection } = state
        const { $from } = selection

        this.editor.chain().focus().setPanelBlock(id).run()

        const newBlockPosition = this.findNewPanelBlockPosition()

        if (newBlockPosition !== null) {
          this.editor.commands.setTextSelection(newBlockPosition)
          this.editor.view.focus()
        }
      }
    },
    findNewPanelBlockPosition() {
      const doc = this.editor.state.doc
      let newBlockPos = null

      doc.descendants((node, pos) => {
        if (node.type.name === 'panelBlock') {
          newBlockPos = pos + 2
          return false
        }
        return true
      })
      return newBlockPos
    },
    // end of the logic for layouts tiptap toolbar
    selectTextEmphasis(value) {
      if (!value) {
        return
      }
      switch (value) {
        case 1:
          this.editor.chain().focus().toggleUnderline().run()
          break
        case 2:
          this.editor.chain().focus().toggleStrike().run()
          break
        case 3:
          this.editor.chain().focus().toggleSuperscript().run()
          break
        case 4:
          this.editor.chain().focus().toggleSubscript().run()
          break
        case 5:
          this.editor.chain().focus().toggleCode().run()
          break
        case 6:
          this.editor
            .chain()
            .focus()
            .unsetUnderline()
            .unsetStrike()
            .unsetSuperscript()
            .unsetSubscript()
            .unsetCode()
            .unsetItalic()
            .unsetBold()
            .run()
          break
        case 7:
          this.editor.chain().focus().selectAll().setTextDirection('ltr').run()
          break
        case 8:
          this.editor.chain().focus().selectAll().setTextDirection('rtl').run()
          break
        case 9:
          this.editor.chain().focus().setHardBreak().run()
          break
      }
    },
    alignItem(value) {
      this.alignment = value
      this.showAlignOptions = false

      if (this.editor.isActive('tableCell') || this.editor.isActive('tableHeader')) {
        const tableElement = this.editor.isActive('tableCell') ? 'tableCell' : 'tableHeader'
        const elementAttributes = this.editor.getAttributes(tableElement)
        let currentStyle = elementAttributes.style || ''

        this.editor
          .chain()
          .focus()
          .updateAttributes(tableElement, {
            style: `${currentStyle.length ? currentStyle + ';' : currentStyle} text-align: ${value}`,
          })
          .run()
        return
      }
      this.editor.chain().focus().setTextAlign(value).run()
    },
    showAlignText() {
      this.showAlignOptions = !this.showAlignOptions
    },
    hideAlignText() {
      this.showAlignOptions = false
    },
    enableTableButton() {
      this.disableTableActions = false
    },
    hideColorPicker() {
      if (this.showTextColorPicker) {
        this.showTextColorPicker = false
      } else if (this.showBackgroundColorPicker) {
        this.showBackgroundColorPicker = false
      }
    },
    addTable() {
      if (this.disableTableActions) return
      this.showTableActions = !this.showTableActions
    },
    setHorizontalRule() {
      this.editor.chain().focus().setHorizontalRule().run()
    },
    setBackgroundColor(color) {
      this.editor.chain().focus().setHighlight({ color }).run()
    },
    unsetBackgroundColor() {
      this.editor.chain().focus().unsetHighlight().run()
    },
    unsetTextColor() {
      this.editor.chain().focus().unsetColor().run()
    },
    setTextColor(color) {
      this.editor.chain().focus().setColor(color).run()
    },
    colorPickerText() {
      if (this.showBackgroundColorPicker) this.showBackgroundColorPicker = false
      this.showTextColorPicker = !this.showTextColorPicker
    },
    colorPickerBackground() {
      if (this.showTextColorPicker) this.showTextColorPicker = false
      this.showBackgroundColorPicker = !this.showBackgroundColorPicker
    },
    setOrderedList() {
      this.editor.chain().focus().toggleOrderedList().run()
    },
    setUnorderedList() {
      this.editor.chain().focus().toggleBulletList().run()
    },
    hideTableActions() {
      this.showTableActions = false
    },
  },
  computed: {
    selectPanelType: {
      get() {
        return this.editor.getAttributes('panelBlock').id || null
      },
      set(value) {
        if (value) {
          this.applyPanelType(value)
        }
      },
    },
    disableTableAndLayout() {
      return this.editor.isActive('panelBlock')
    },
    disableAnchor() {
      return (
        this.editor.isActive('image') ||
        this.editor.isActive('figure') ||
        this.editor.isActive('listItem') ||
        this.selectedText == '' ||
        this.clickedOnBlockquote
      )
    },
    disablePanels() {
      return (
        this.editor.isActive('image') ||
        this.editor.isActive('figure') ||
        this.editor.isActive('listItem') ||
        this.editor.isActive('columnBlock') ||
        this.editor.isActive('threeColumnBlock') ||
        this.editor.isActive('panelBlock') ||
        this.selectedText
      )
    },
    isAnchor() {
      return (
        this.editor.getAttributes('paragraph')['data-anchor-label'] ||
        this.editor.getAttributes('heading')['data-anchor-label']
      )
    },
    selectedText() {
      return this.$store.state.tiptap.selectedText
    },
    clickedOnBlockquote() {
      return this.$store.state.tiptap.clickedOnBlockquote || this.editor.isActive('blockquote')
    },
    alignIcon: {
      get() {
        return `uil uil-align-${this.alignment}`
      },
    },
    selectHeading: {
      get() {
        if (this.editor.getAttributes('heading')?.level == 1) {
          return 0
        }
        return this.editor.getAttributes('heading').level || 0
      },
      set(value) {
        if (value === 0) {
          this.editor.chain().focus().setParagraph().run()
        }
        this.editor.chain().focus().setHeading({ level: value }).run()
      },
    },
    textActionsDisabled() {
      return this.editor.isActive('image')
    },
    imageActionsDisabled() {
      return this.editor.isActive('image') || this.editor.isActive('figure')
    },
    linkActionDisabled() {
      return this.editor.isActive('image') || this.selectedText == ''
      // return false
    },
  },
}
</script>
<style lang="scss" scoped>
.baseEditorTable {
  z-index: 9999;

  .baseEditorTable--popup {
    transform: translate(-20%, 20px);
  }
}
.button-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .border-right-light {
    border-right: 1px solid #edeef2;
  }
  button,
  .btn-popper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    min-height: 32px;
    margin: 0 0.7rem;
    border-radius: 4px;
    background: #fff;
    border: none;
    padding: 4px 6px 2px 6px;
    &:disabled {
      color: #d3d3d8;
    }
    &:hover {
      background: #f5f5f5;
    }
  }
}

.alignment-btn {
  margin: 0 0.7rem;
  border-radius: 4px;
  background: #fff;
  border: none;
  padding: 4px 6px 2px 6px;
  cursor: pointer;
  &:disabled {
    color: #d3d3d8;
  }
  &:hover {
    background: #f5f5f5;
  }

  &.active {
    background: #0098f7;
    color: white;
    border-bottom: 0.5px solid white;
    .textEmphasisOptions--option__icon {
      opacity: 0.6;
      background: #eff0f2;
      color: #7a869a;
    }
  }
}
.is-active {
  color: #0098f7;
}
</style>
<style lang="scss">
/* Base column block styles */
.ProseMirror .sidebarColumn {
  width: 100%;
  display: grid;
  gap: 24px;
  padding: 8px 0;
  min-width: 300px; /* Ensuring column block has sufficient width */
}

/* Base column styles */
.ProseMirror .column {
  overflow: auto;
  border: 1px dashed gray;
  border-radius: 8px;
  padding: 8px;
  margin: -8px;

  p {
    margin: 0 0 4px 8px;
  }
}

.ProseMirror #left-sidebar {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 24px;
  padding: 8px 0;
}

.ProseMirror #right-sidebar {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 24px;
  padding: 8px 0;
}
.ProseMirror #error,
.ProseMirror #success,
.ProseMirror #info,
.ProseMirror #warning {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  hr {
    margin-left: 42px;
  }

  blockquote p {
    margin-left: 0;
  }
  ol,
  ul {
    margin-left: 24px;
  }

  ul p,
  ol p {
    margin-left: 0;
  }
  .tableWrapper {
    margin-left: 42px;
  }
}
.ProseMirror #success {
  border: none;
  position: relative;
  padding: 8px;
  background: #def7e5;
  border-radius: 4px;
  margin-top: 16px;

  .column {
    padding: 8px;
    border: none;
  }
  .column::before {
    content: '\2713';
    font-size: 18px;
    height: 28px;
    width: 28px;
    text-align: center;
    position: absolute;
    top: calc(30% - 6px);
    left: 12px;
    color: #fff;
    background-color: green;
    border-radius: 100%;
  }
}

.ProseMirror #info {
  background: #f0f3ff;
  border: none;
  position: relative;
  padding: 8px;
  border-radius: 4px;
  margin-top: 16px;

  .column {
    padding: 8px;
    border: none;
  }
  .column::before {
    content: '\2139';
    font-size: 16px;
    height: 28px;
    width: 28px;
    text-align: center;
    position: absolute;
    top: calc(30% - 5px);
    left: 12px;
    color: #fff;
    background-color: lightblue;
    border-radius: 100%;
  }
}

.ProseMirror #error {
  background: #ffeced;
  border: none;
  position: relative;
  padding: 8px;
  border-radius: 4px;
  margin-top: 16px;

  .column {
    padding: 8px;
    border: none;
  }
  .column::before {
    content: '\2717';
    font-size: 19px;
    height: 28px;
    width: 28px;
    text-align: center;
    position: absolute;
    top: calc(30% - 5px);
    left: 12px;
    color: #fff;
    background-color: lightcoral;
    border-radius: 100%;
  }
}

.ProseMirror #warning {
  border: none;
  position: relative;
  padding: 8px;
  background: #fff3e9;
  border-radius: 4px;
  margin-top: 16px;

  .column {
    padding: 8px;
    border: none;
  }
  .column::before {
    content: '\26A0';
    font-size: 24px;
    position: absolute;
    top: calc(30% - 8px);
    left: 12px;
    color: #f28c28;
  }
}

.text-alignment-options {
  position: absolute;
  top: 100%;
  border: 1px solid #cfd8dc;
  background: #fff;
  border-radius: 4px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 12%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
  }

  &::after {
    top: -5px;
    border-width: 0px 5px 5px 5px;
    border-color: transparent transparent #fff transparent;
  }

  &::before {
    top: -6px;
    border-width: 0px 5px 5px 5px;
    border-color: transparent transparent #cfd8dc transparent;
  }
}
.popover-content-padding {
  padding: 0.3rem 0rem !important;
}

.popover-wrapper-custom {
  .textEmphasisOptions {
    border: 1px solid #cfd8dc;
    border-radius: 4px;
    background: #fff;
    min-width: 180px !important;
    color: #7a869a;

    .textEmphasisOptions--option__icon {
      padding: 2px 4px;
      border-radius: 4px;
      background: #eff0f2;
      color: #7a869a;
    }
  }

  .textEmphasisOptions {
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
    }

    &::after {
      top: -5px;
      border-width: 0px 5px 5px 5px;
      border-color: transparent transparent #fff transparent;
    }

    &::before {
      top: -6px;
      border-width: 0px 5px 5px 5px;
      border-color: transparent transparent #cfd8dc transparent;
    }
  }
}

// Text alignment needs to change together with the direction
.ProseMirror p[dir='rtl'],
.ProseMirror h1[dir='rtl'],
.ProseMirror h2[dir='rtl'],
.ProseMirror h3[dir='rtl'],
.ProseMirror h4[dir='rtl'],
.ProseMirror h5[dir='rtl'],
.ProseMirror h6[dir='rtl'],
.ProseMirror li[dir='rtl'],
.ProseMirror img[dir='rtl'],
.ProseMirror figure[dir='rtl'],
.ProseMirror th[dir='rtl'],
.ProseMirror td[dir='rtl'],
.ProseMirror table[dir='rtl'],
.ProseMirror tr[dir='rtl'] {
  direction: rtl;
  text-align: right;
}

.ProseMirror p[dir='ltr'],
.ProseMirror h1[dir='ltr'],
.ProseMirror h2[dir='ltr'],
.ProseMirror h3[dir='ltr'],
.ProseMirror h4[dir='ltr'],
.ProseMirror h5[dir='ltr'],
.ProseMirror h6[dir='ltr'],
.ProseMirror li[dir='ltr'],
.ProseMirror img[dir='ltr'],
.ProseMirror figure[dir='ltr'],
.ProseMirror th[dir='ltr'],
.ProseMirror td[dir='ltr'],
.ProseMirror table[dir='ltr'],
.ProseMirror tr[dir='ltr'] {
  direction: left;
  text-align: left;
}

.ProseMirror table:has(tr[dir='rtl']) {
  margin: 0 0 0 auto;
}

@for $i from 2 through 6 {
  $font-size-increment: 1.4rem - ($i * 0.1rem);

  .heading-shortcut-icon-#{$i}:not(.isMac) {
    font-size: $font-size-increment;

    &:after {
      content: 'Ctrl + Alt + #{$i}';
    }
  }

  .heading-shortcut-icon-#{$i}.isMac {
    font-size: $font-size-increment;

    &:after {
      content: '⌘⌥#{$i}';
    }
  }
}

.ProseMirror .two-column-block {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 24px;
  padding: 8px 0;
}

.ProseMirror .column {
  overflow: auto;
  border: 1px gray dashed;
  border-radius: 8px;
  padding: 8px;
  margin: -8px;
}

.ProseMirror .three-column-block {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 24px;
  padding: 8px 0;
}

.tiptap-base-select {
  .heading-shortcut-icon-1,
  .heading-shortcut-icon-2,
  .heading-shortcut-icon-3,
  .heading-shortcut-icon-4,
  .heading-shortcut-icon-5,
  .heading-shortcut-icon-6 {
    padding: 8px !important;
    color: #1e1d62 !important;
    &:after {
      font-size: 13px;
      float: right;
      padding: 2px 4px;
      border-radius: 4px;
      background: #eff0f2;
      color: #7a869a;
    }
  }
}
@for $i from 2 through 6 {
  .highlighted-option-#{$i} {
    .heading-shortcut-icon-#{$i} {
      background: #0098f7;
      color: white !important;
      border-bottom: 0.5px solid white;
      &:hover {
        color: #1e1d62 !important;
      }
      &:after {
        opacity: 0.6;
        background: #eff0f2;
        color: #7a869a;
      }
    }
  }
}
.top {
  margin: 0 auto;
  display: table;
  position: absolute;
}

.action-disabled {
  pointer-events: none;
  color: #d3d3d8;
  background: #f5f5f5 !important;
}
</style>

<style lang="scss">
.popper {
  z-index: 1031 !important;
  background: #191919;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  width: fit-content;
  white-space: nowrap;
}

.popper > :not(.textEmphasisOptions)::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #191919 transparent transparent transparent;
}
.layout-options {
  position: absolute;
  top: 100%;
  border: 1px solid #cfd8dc;
  background: #fff;
  border-radius: 4px;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 12%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
  }

  &::after {
    top: -5px;
    border-width: 0px 5px 5px 5px;
    border-color: transparent transparent #fff transparent;
  }

  &::before {
    top: -6px;
    border-width: 0px 5px 5px 5px;
    border-color: transparent transparent #cfd8dc transparent;
  }
}
.popover-content-padding {
  padding: 0.3rem 0rem !important;
}

.layout-btn {
  margin: 0 0.7rem;
  border-radius: 4px;
  background: #fff;
  border: none;
  padding: 4px 6px 2px 6px;
  cursor: pointer;
  &:disabled {
    color: #d3d3d8;
  }
  &:hover {
    background: #f5f5f5;
  }

  &.active {
    background: #0098f7;
    color: white;
    border-bottom: 0.5px solid white;
    .textEmphasisOptions--option__icon {
      opacity: 0.6;
      background: #eff0f2;
      color: #7a869a;
    }
  }
}
.is-active {
  color: #0098f7;
}
.is-full-screen.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 1049;
  padding: 1rem;
  overflow: auto;
}
.no-scroll {
  overflow: hidden;
}
</style>
