<template>
  <div v-if="page">
    <div class="row mb-1">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/site-pages` }"
            data-tooltip="Back to pages"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>

          <span class="mr-3 ml-2">{{ page.title }}</span>

          <a v-if="page.full_url" target="_blank" :href="page.full_url">{{ page.path }}</a>

          <span v-else class="text-muted clickable" @click="copyValue(page.path)">
            {{ page.path }} {{ $attrs.value }}
          </span>
        </h3>

        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(page.updated_at) }}</span>

          <hr class="vertical mx-2" />

          <span>Published: {{ $dateRelative(page.created_at) }}</span>

          <hr class="vertical mx-2" />

          <span class="badge bg-azure" v-if="page.site">{{ page.site.name }}</span>

          <router-link
            tag="span"
            class="badge bg-orange ml-2 clickable"
            v-if="page.template.name"
            :to="{ path: `/site-templates/${page.template_id}` }"
          >
            {{ page.template.name }}
          </router-link>

          <span class="badge bg-purple ml-2" v-if="page.market">{{ page.market?.label }}</span>

          <PageStatusBadge :page="page" class="ml-2" />

          <span class="badge bg-red ml-2" v-if="readOnly">readonly</span>
        </div>
      </div>

      <div class="col-auto my-auto">
        <button @click="preview" class="btn btn-secondary mr-2" v-if="this.page.preview_link">
          <i class="uil uil-eye mr-2"></i>
          Preview page
        </button>

        <button
          class="btn btn-primary mr-2"
          id="submit-cta"
          :disabled="showColaborativeNotification === true || readOnly || loading"
          @click="readOnly ? null : update()"
          v-bind:class="{ loading: loading }"
        >
          <i class="uil uil-check"></i>
          Save
        </button>
      </div>
    </div>

    <div class="mb-2 mt-2">
      <colaborative-edit-notification
        v-if="showColaborativeNotification"
        :route_path="$route.fullPath"
        :username="username"
      />
    </div>

    <ul class="nav nav-tabs mb-3">
      <li v-for="(tab, index) in tabs" v-bind:class="{ active: activeTab === tab }" v-bind:key="index">
        <a @click="tabClick(tab)">{{ tab === 'seo' ? 'SEO' : $capitalize(tab) }}</a>
      </li>
    </ul>

    <page-edit-general
      :page="page"
      ref="general"
      v-if="activeTab === 'general'"
      @save="update(false)"
      :readOnly="readOnly"
    />

    <page-edit-seo
      :relationalData="relationalData"
      :page="page"
      v-if="activeTab === 'seo'"
      :readOnly="readOnly"
      @updated-date="updatedDate"
    />

    <page-edit-advanced
      :page="page"
      v-if="activeTab === 'advanced'"
      @refresh="getPage()"
      :showColaborativeNotification="showColaborativeNotification"
    />

    <page-edit-relation :page="page" v-if="activeTab === 'event'" model="site_event" :id="page.relation_id" />

    <edit-section-modules
      v-if="activeTab === 'sections'"
      :sections="page.sections"
      @save="update()"
      @moduleAdded="moduleAdded"
      :siteId="page.site_id"
      :affiliateId="page.site.affiliate_id"
      :marketId="page.market_id"
      :pageId="page.id"
      :showPreview="true"
      :pageTitle="page.title"
      :moduleOwnerId="$route.params.id"
      moduleOwnerType="page"
    />

    <page-reindex-modal ref="reindexModal" @reindexPage="reindexPage" />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import PagesService from '@services/PagesService.js'

import PageEditGeneral from '@pages/cms/pages/edit/general.vue'
import PageEditSeo from '@pages/cms/pages/edit/seo.vue'
import PageEditRelation from '@pages/cms/pages/edit/relation.vue'
import PageEditAdvanced from '@pages/cms/pages/edit/advanced.vue'

import PageStatusBadge from '@atoms/table/cell/pages-status.vue'
import PageReindexModal from '@molecules/cms/pages/reindex-modal.vue'

import EditSectionModules from '@templates/cms/section/section.vue'
import SiteEventsService from '@services/SiteEventsService.js'
import ColaborativeEditNotification from '@molecules/colaborative-edit-notification.vue'
import PusherInit from '@mixins/PusherInit.js'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import { computed } from 'vue'
import _ from 'lodash'

const confirmUpdate = async (
  title,
  content,
  extraContent,
  actionButtonTitle,
  actionButtonIconClass,
  actionButtonClass
) => {
  return await openDialog(ConfirmDialog, {
    title,
    content,
    extraContent,
    actionButtonTitle,
    actionButtonIconClass,
    actionButtonClass,
  })
}

export default {
  data() {
    return {
      tabs: [],
      activeTab: 'general',
      initialPage: {},
      initialStatus: null,
      page: null,
      loading: false,
      relationalData: null,
      socket: null,
      version: '',
      modelName: 'sites-pages',
      contributorWatch: [],
      noContributorSelected: false,
    }
  },
  components: {
    PageEditSeo,
    PageEditGeneral,
    EditSectionModules,
    PageEditAdvanced,
    PageStatusBadge,
    PageEditRelation,
    PageReindexModal,
    ColaborativeEditNotification,
  },
  mixins: [PusherInit],
  beforeMount() {
    if (this.$route.hash) {
      this.activeTab = this.$route.hash.replace('#', '')
    }
  },
  provide() {
    return {
      getTemplateId: computed(() => this.page?.template_id),
    }
  },
  unmounted() {
    this.$store.dispatch('media/setSiteAndMarket', {
      site_id: null,
      market_id: null,
    })
  },
  created() {
    this.getPage()
    setTimeout(() => {
      this.$store.commit('dynamicForm/setInitFormObject', _.cloneDeep(this.page))
      this.$store.commit(
        'dynamicForm/setFormSubmitted',
        this.$store.state.dynamicForm.formObject === this.$store.state.dynamicForm.initFormObject
      )

      this.contributorWatch = this.$refs?.general?.contributors
    }, 2000)
  },
  methods: {
    handleFormClick() {
      if (!Object.keys(this.$store.state.dynamicForm.initFormObject).length) {
        this.$store.commit('dynamicForm/setInitFormObject', { ...this.page })
      }
    },
    copyValue(value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          this.showSuccessMessage('Copied to your clipboard.')
        })
        .catch((err) => {
          this.showErrorMessage('Failed to copy text: ', err)
        })
    },
    async updatedDate() {
      await PagesService.getOne(this.$route.params.id)
        .then((response) => {
          let page = response.data.result[0]
          this.page.updated_at = page.updated_at
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    async getPage() {
      this.setDefaultTabs()
      await PagesService.getOne(this.$route.params.id, {
        with: [
          'sections',
          'sections.enabled_modules',
          'market',
          'site',
          'site_market',
          'site_market.market',
          'template',
          'template.blocks',
          'author',
          'authors',
          'categories',
          'countries',
          'schemas',
        ].join(),
      })
        .then((response) => {
          if (response.data.result.length === 0) {
            this.$router.push('/site-pages')
          } else {
            let page = response.data.result[0]
            this.initialStatus = page.status
            page.relation_label = null
            page.sections.forEach((section) => {
              section.module_values.forEach((moduleValue) => {
                moduleValue.visible = false
              })
            })
            page.categories.forEach((category) => {
              delete category.pivot
              category.primary = !!category.primary
            })
            page.authors.forEach((author) => {
              author.contribution_value = author.pivot.contribution_value
              author.contribution_value_id = author.pivot.contribution_value_id
              delete author.pivot
            })
            this.initialPage = Object.assign({}, page)
            this.page = page
            window.siteUrl = this.page.site.url
            this.setTitle(`${this.page.title}`)
            this.$store.dispatch('media/setSiteAndMarket', {
              site_id: this.page.site.affiliate_id,
              market_id: this.page.market_id,
            })
            if (page.type === 'event') {
              this.tabs.push(page.type)
              SiteEventsService.get({
                id: this.page.relation_id,
                with: 'event,event.home_team.locales,event.away_team.locales,event.sport',
              }).then((response) => {
                this.relationalData = response.data.result[0]
              })
            }
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    setDefaultTabs() {
      let tabs = ['general', 'sections', 'seo']
      if (this.hasPermission('misc.sites/advanced-page-settings')) {
        tabs.push('advanced')
      }
      this.tabs = tabs
      this.activeTab = 'general'
    },
    moduleAdded(module) {
      if (
        module === 'content' &&
        this.page.site.type === 'sports' &&
        this.page.template.type === 'event' &&
        this.page.path_modified_for_reindex == false
      ) {
        this.$refs.reindexModal?.show(this.page)
      }
    },
    reindexPage(path) {
      // currently used by SEO sport sites so autogenerated pages are crawled again.
      this.page.path = path
      this.page.modify_path_for_reindex = true
      this.page.path_modified_for_reindex = true
    },
    validatePage() {
      if (['operator', 'game', 'payment_method', 'software_provider'].includes(this.page.template.type)) {
        if (this.page.relation_id === null && this.page.active) {
          this.showErrorMessages(
            `Cannot save an active page without a selected ${this.$prettyLabels(this.page.template.type)}`
          )
          return false
        }
      }
      return true
    },
    async update(validate = true) {
      const valid = validate ? this.validatePage() : true
      if (!valid) return false
      this.loading = true
      let check = true

      if (this.initialStatus !== 'inactive' && this.page.status == 'inactive') {
        check = await confirmUpdate(
          'Warning',
          `Please check redirects associated with this page`,
          '',
          'Ok',
          'uil uil-info-circle',
          'btn btn-warning'
        )
      }

      if (this.noContributorSelected && check) {
        this.showErrorMessage('You must select an author contributor before saving changes')
      }

      if (check && !this.noContributorSelected) {
        await PagesService.update(this.page)
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              // set visible modules to the updated page object
              const beforeEditSections = this.page.sections
              if (response.data.result.sections) {
                response.data.result.sections.map((section, sectionIndex) => {
                  section.module_values.map((module, moduleIndex) => {
                    module.visible = beforeEditSections[sectionIndex].module_values[moduleIndex]?.visible
                  })
                })
              }
              this.loading = false
              let page = response.data.result
              page.relation_label = null

              page.authors.forEach((author) => {
                author.contribution_value = author.pivot.contribution_value
                author.contribution_value_id = author.pivot.contribution_value_id
                delete author.pivot
              })

              this.page = page
              this.showSuccessMessage('Page saved')
              this.$cookies.set('formIsDirty', false)
              this.initialStatus = page.status
            }
          })
          .catch(this.showUnknownErrorMessage)
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = false
      }
    },
    preview() {
      window.open(this.page.preview_link)
    },
    tabClick(tab) {
      this.activeTab = tab
      history.replaceState(undefined, undefined, `#${tab.toLowerCase()}`)
    },
  },
  computed: {
    checkPageStatus() {
      return this.initialStatus
    },
    readOnly() {
      if (this.showColaborativeNotification) {
        return true
      }
      if (this.hasPermission('misc.sites/limited-to-own-pages')) {
        return this.$auth.user().id !== this.page.created_by_user_id
      }
      return false
    },
    watchPage() {
      return Object.assign({}, this.page)
    },
  },
  watch: {
    watchPage: {
      handler(newValue) {
        if (Object.keys(this.initialPage).length === Object.keys(newValue).length) {
          if (this.initialPage['relation_id'] !== null && this.initialPage['relation_label'] === null) {
            this.initialPage['relation_label'] = newValue['relation_label']
          }
          if (
            this.initialPage['schemas'].length === 0 &&
            newValue['schemas'].length === 1 &&
            newValue['schemas'][0]['json_schema'] === ''
          ) {
            this.initialPage['schemas'] = newValue['schemas']
          }
        }

        this.$store.commit('dynamicForm/setFormObject', _.cloneDeep(newValue))
      },
      deep: true,
    },
    contributorWatch: {
      handler(contributors) {
        contributors?.forEach((contributor) => {
          if (contributor.id === 0) {
            this.noContributorSelected = true
          } else {
            this.noContributorSelected = false
          }
        })
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.modules {
  z-index: 1;
}
</style>
