const state = {
  formObject: {},
  initFormObject: {},
  formSubmitted: true,
  formIsDirty: false,
  toplistCount: {},
}

const getters = {
  getFormObject: (state) => {
    return state.formObject
  },
  getInitFormObject: (state) => {
    return state.initFormObject
  },
  getFormSubmitted: (state) => {
    return state.formSubmitted
  },
}

const mutations = {
  setFormObject(state, value) {
    state.formObject = value
  },
  setInitFormObject(state, value) {
    state.initFormObject = value
  },
  setFormSubmitted(state, value) {
    state.formSubmitted = value
  },
  setFormIsDirty(state, value) {
    state.formIsDirty = value
  },
  setToplistCount(state, value) {
    state.toplistCount = {
      count: value.count,
      id: value.id,
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
