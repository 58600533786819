<template>
  <div class="card">
    <div class="card-body">
      <dynamic-form
        ref="form"
        form-type="edit"
        :formFields="structure.list"
        :structure="structure"
        :object="object"
        :on-submit="submit"
        :on-remove="remove"
        :path="config.url"
      />
    </div>
  </div>
</template>

<script>
import DynamicForm from '@/components/fields/DynamicForm.vue'
import Section from '@models/cms/Section.js'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import SectionsService from '@services/SectionsService.js'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

export default {
  components: { ConfirmDialog, DynamicForm },
  data() {
    return {
      object: {},
      structure: { list: [] },
      config: Section.getConfig(),
    }
  },
  mounted() {
    this.getSection()
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  methods: {
    getSection() {
      SectionsService.getOne(this.$route.params['id'], {
        with: 'site,enabled_modules',
      }).then((response) => {
        this.object = response.data.result[0]
        this.getStructure()
      })
    },
    getStructure() {
      this.structure = Section.getStructure()
    },
    submit() {
      this.$refs.form
        .process(this.object)
        .then((response) => {
          return SectionsService.update(response)
        })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage(`${this.structure.config.name} updated`)
            this.$parent.$parent.getSection()
          }
        })
        .catch(() => this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
    async remove() {
      if (await confirmDelete('Warning', 'Are you sure you want to delete this item?')) {
        SectionsService.remove({ id: this.$route.params['id'] })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage(`${this.structure.config.name} removed`)
              this.$router.push('/operators')
            }
          })
          .catch(this.showUnknownErrorMessage)
      }
    },
  },
}
</script>
