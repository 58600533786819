<template>
  <div v-if="section">
    <div class="card bg-white-25 pb-4">
      <div class="card-header pb-0" v-if="showCardHeader">
        <h3 class="card-title">Modules</h3>
        <div class="right-button">
          <button class="btn btn-info" v-on:click="moduleDropdownClick()">
            <i class="uil uil-focus-add"></i>
            Add new module
          </button>
        </div>
      </div>
      <draggable
        :key="rerender"
        filter=".dropdown"
        class="pt-4 section-modules"
        :list="section.module_values"
        handle=".module-drag"
        :item-key="'uuid' | 'id'"
      >
        <template #item="{ element, index }">
          <module
            :index="index"
            :page-module="element"
            :allowModuleDelete="allowModuleDelete"
            :openModuleValues="openModuleValues"
            :moduleOwnerType="moduleOwnerType"
            :siteId="siteId"
            :affiliateId="affiliateId"
            :id="`section-module-${index}`"
            :marketId="marketId"
            :hasTemplate="hasTemplate"
            @delete="removeModule"
            @addModule="(idx) => moduleDropdownClick(index)"
            @previewModule="(idx) => $emit('previewModule', idx)"
            :showPreview="showPreview"
            :pageTitle="pageTitle"
            ref="pageModule"
            @reRenderList="reRenderList"
          />
        </template>
      </draggable>
    </div>
    <div class="card border" v-if="section.extra_fields && section.extra_fields.length > 0">
      <div class="card-header pb-0">
        <div class="form-inline">
          <h3>Extra fields</h3>
          <span
            class="ml-2 text-muted clickable"
            @click="() => $router.push(`/site-sections/${section.id}/extra-fields`)"
            >manage</span
          >
        </div>
      </div>
      <div class="card-body">
        <dynamic-field
          v-for="(field, key) in section.extra_fields"
          v-bind:key="key"
          :field="field"
          :siteSpecific="true"
          :siteId="siteId"
          :marketId="marketId"
        />
      </div>
    </div>
    <div class="card border" v-if="moduleOwnerType == 'market'">
      <div class="card-header pb-0">
        <h3>Section Styles</h3>
      </div>
      <div class="card-body">
        <base-select
          label="Section Styles"
          v-model="sectionStyleId"
          :path="`hercules/sites/section-styles?site_id=${siteId}&section_id=${section.id}&market_id=${marketId}`"
          optionLabelKey="name"
          @update:modelValue="changedSection"
          :allowNullOption="true"
        />
      </div>
    </div>
    <add-module-modal ref="addModuleModal" :section="section" @addModule="easyAddModule" />
  </div>
</template>
<script>
import Module from '@organisms/cms/modules/module.vue'
import Draggable from 'vuedraggable'
import ModulesService from '@services/ModulesService.js'
import ModuleValue from '@constants/module-value.js'
import MultiSelect from 'vue-multiselect'
import AddModuleModal from '@molecules/cms/modules/add-module.vue'
import DynamicField from '@/components/fields/dynamic-field.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import TemplateService from '@services/TemplatesService'

export default {
  created() {
    this.section.module_values.map((item) => {
      item.visible = false
      item.removed = false
      item.added = false
      item.soft_remove = false
    })

    this.sectionStyleId = this.section.style?.section_style_id
  },
  inject: ['getTemplateId'],
  async beforeMount() {
    await this.checkTemplateType()
  },
  data() {
    return {
      loading: false,
      search: null,
      rerender: 0,
      sectionStyleId: null,
      hasTemplate: false,
    }
  },
  components: {
    Module,
    Draggable,
    DynamicField,
    MultiSelect,
    AddModuleModal,
    BaseSelect,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    allowModules: {
      type: Boolean,
      default: () => true,
    },
    allowPrefilledModules: {
      type: Boolean,
      default: () => true,
    },
    allowModuleDelete: {
      type: Boolean,
      default: () => true,
    },
    openModuleValues: {
      type: Boolean,
      default: () => false,
    },
    moduleOwnerType: {
      type: String,
      required: false,
    },
    moduleOwnerId: {
      required: false,
    },
    siteId: {
      required: true,
    },
    affiliateId: {
      required: true,
    },
    showCardHeader: {
      default: () => true,
    },
    showPreview: {
      default: () => false,
    },
    marketId: {},
    pageTitle: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    async checkTemplateType() {
      if (!this.getTemplateId) {
        return
      }
      try {
        const response = await TemplateService.getOne(this.getTemplateId)
        if (response.data.success) {
          response.data.result.forEach((item) => {
            if (item.enable_cards_v2_multiple_selections === 1 && item.short_name === 'homepage') {
              this.hasTemplate = true
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    changedSection() {
      this.$emit('changeSection', this.sectionStyleId)
    },
    moduleDropdownClick(index = null) {
      this.$refs.addModuleModal.show(index)
    },
    easyAddModule(module, index) {
      switch (module.moduleType) {
        case 'Template Block':
          this.addTemplateBlock(module, index)
          break
        case 'Prefilled Module':
          this.addPrefilledModule(module, index)
          break
        case 'Module':
          this.addModule(module, index)
          break
      }
    },
    addModule(module, index) {
      if (
        module.name.toLowerCase() === 'anchor' &&
        this.section.module_values.filter((item) => item.module && item.module.id === module.id).length !== 0
      ) {
        return this.showErrorMessage('You cannot have more than 1 anchor module')
      }
      ModulesService.add({ module_id: module.id, section_id: this.section.id, site_id: this.siteId })
        .then((response) => {
          let moduleValue = response.data.result
          moduleValue.owner_type = this.moduleOwnerType
          moduleValue.owner_id = this.moduleOwnerId
          moduleValue.active = true
          moduleValue.visible = false
          moduleValue.removed = false
          moduleValue.added = false
          moduleValue.soft_remove = false
          moduleValue.uuid = this.generateUID()
          this.pushModule(index, moduleValue)
          this.showSuccessMessage(`${this.capitalize(module.name)} module added`)
          this.$emit('moduleAdded', module.name)
          this.search = null
        })
        .catch(this.showUnknownErrorMessage)
    },
    reRenderList() {
      // hacky way to force rerendering of the list
      this.rerender += 1
    },
    addPrefilledModule(prefilledModule, index) {
      const valueType =
        this.moduleOwnerType === ModuleValue.OWNER_TYPE_TEMPLATE
          ? ModuleValue.VALUE_TYPE_PREFILLED_MODULE // not market specific (template)
          : ModuleValue.VALUE_TYPE_PREFILLED_MODULE_MARKET

      const moduleValue = {
        owner_type: this.moduleOwnerType,
        owner_id: this.moduleOwnerId,
        prefilled_module_name: prefilledModule.name,
        value_type: valueType,
        active: true,
        visible: false,
        prefilled_module_id: prefilledModule.id,
        value_id: prefilledModule.id,
        section_id: this.section.id,
        removed: false,
        added: false,
        soft_remove: false,
        module: { label: prefilledModule.module.label, id: prefilledModule.module_id },
      }
      this.pushModule(index, moduleValue)
      this.showSuccessMessage('Prefilled module added')
    },
    pushModule(index, value) {
      if (index == undefined) {
        this.section.module_values.push(value)
        index = this.section.module_values.length - 1
      } else {
        this.section.module_values.splice(index, 0, value)
      }
      this.$nextTick(() => {
        this.section.module_values[index].visible = true
      })
      return index
    },
    addTemplateBlock(block, index) {
      if (this.section.module_values.filter((module) => module.value_id === block.id).length != 0) {
        return this.showErrorMessage('Template block already exists')
      }
      const moduleValue = {
        owner_type: this.moduleOwnerType,
        owner_id: this.moduleOwnerId,
        template_block_name: block.name,
        template_block_description: block.description,
        value_type: ModuleValue.VALUE_TYPE_TEMPLATE_BLOCK,
        active: true,
        value_id: block.id,
        section_id: this.section.id,
        visible: false,
        removed: false,
        added: false,
        soft_remove: false,
      }
      this.pushModule(index, moduleValue)
      this.showSuccessMessage('Template block added')
    },
    removeModule(id) {
      const index = this.section.module_values.findIndex((item) => {
        return item.id === id
      })
      this.section.module_values.splice(index, 1)
      this.showSuccessMessage('Module deleted')
    },
  },
}
</script>

<style scoped>
.section-modules > div {
  cursor: auto;
}
.right-button {
  display: block;
  margin-left: auto;
  margin-bottom: -12px;
  white-space: nowrap;
}
</style>
