<template>
  <div class="card">
    <dynamic-form
      ref="form"
      form-type="create"
      :formFields="structure.list"
      :object="widget"
      :on-submit="update"
      :inCard="true"
      :path="config.url"
      :fixedButtons="true"
    />
  </div>
</template>

<script>
import WidgetsToplistService from '@services/WidgetsToplistService.js'
import Widget from '@models/widgets/toplist/general.js'

import DynamicForm from '../../../../fields/DynamicForm.vue'

export default {
  components: { DynamicForm },
  data() {
    return {
      widget: null,
      config: Widget.getConfig(),
    }
  },
  created() {
    this.getWidget()
    this.structure = Widget.getStructure()
  },
  mounted() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  methods: {
    getWidget() {
      WidgetsToplistService.getOne(this.$route.params.id, {
        with: 'site,market,components',
      }).then((response) => {
        this.widget = response.data.result[0]
      })
    },
    update() {
      WidgetsToplistService.update(this.widget)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.widget = response.data.result[0]
            this.$parent.$parent.widgetsList = response.data.result[0]
            this.showSuccessMessage('Widget saved')
            this.$parent.$parent.getTopList()
          }
          this.loading = false
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
  },
}
</script>
