<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bulk editing {{ ids.length }} pages</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <div v-show="showStatus">
            <base-checkbox v-model="changeStatus" name="changeStatus" label="Change status" />
            <base-radio
              :data="available_statuses"
              v-model="status"
              name="status"
              v-if="changeStatus"
              :inlineLayout="true"
            />
            <base-date
              v-if="status && status.value === 'scheduled'"
              label="Scheduled at"
              v-model="scheduledAt"
              :config="datePickerConfig()"
            />
            <hr />
          </div>
          <div>
            <base-checkbox v-model="changeAuthors" name="changeAuthors" label="Change authors" />
            <base-multiselect
              :multiple="true"
              field="authors"
              label="Authors"
              v-if="changeAuthors"
              :path="`hercules/sites/authors?site_id=${siteId}&orderBy=name&ascending=1`"
              :value="authors"
              @sync="(field, value) => (authors = value ? value : null)"
            />
            <hr />
          </div>
          <div>
            <base-checkbox v-model="changeReviewer" name="changeReviewer" label="Change reviewer" />
            <base-select
              label="Reviewer"
              v-if="changeReviewer"
              v-model="reviewer"
              :path="`hercules/sites/authors?site_id=${siteId}`"
              option-label-key="name"
            />
            <hr />
          </div>
          <div v-show="showCategories || true">
            <base-checkbox
              v-model="changeCategories"
              v-if="categoriesVisible"
              name="changeCategories"
              label="Change Categories"
            />
            <base-checkbox-list
              label="Categories Rroni"
              identifier="short_name"
              v-if="changeCategories || true"
              :path="`hercules/sites/categories`"
              ref="categoryList"
              :parameters="categoryParams"
              :disabled="!changeCategories"
              noOptionsPlaceholder="No Categories Available"
            />
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <div>
            <button
              class="btn btn-danger"
              :class="{ loading: loading }"
              @click="remove()"
              v-show="showDelete"
            >
              Delete
            </button>
          </div>
          <div>
            <button class="btn btn-secondary" :class="{ loading: loading }" @click="close()">Cancel</button>
            <button class="btn btn-primary ml-2" :class="{ loading: loading }" @click="submit()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PagesService from '@services/PagesService.js'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseRadio from '@atoms/fields/base-radio.vue'
import BaseDate from '@atoms/fields/base-date.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import BaseMultiselect from '@/components/fields/base-multiselect.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import BaseCheckboxList from '@atoms/fields/base-checkbox-list.vue'
import { openDialog } from 'vue3-promise-dialog'
import moment from 'moment'

const confirmDelete = async (title, content, actionButtonTitle) => {
  return await openDialog(ConfirmDialog, { title, content, actionButtonTitle })
}

export default {
  components: { BaseCheckbox, BaseRadio, BaseSelect, BaseMultiselect, BaseDate, BaseCheckboxList },
  data() {
    return {
      ids: [],
      loading: false,
      changeStatus: false,
      changeAuthors: false,
      changeCategories: false,
      categoriesVisible: false,
      changeReviewer: false,
      categoryParams: {},
      status: null,
      scheduledAt: null,
      authors: null,
      reviewer: null,
      available_statuses: [],
    }
  },
  watch: {
    changeStatus: function () {
      if (!this.changeStatus) this.status = null
    },
  },
  props: {
    siteId: {
      required: true,
    },
    stateName: {
      type: String,
      default: () => 'data-table',
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    showCategories: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show(ids) {
      this.ids = ids
      this.$refs.modal.classList.add('show')
      this.getCategoryParams()
    },
    close() {
      this.loading = false
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.$emit('closed')
    },
    buildUpdateObject() {
      let object = {}
      if (this.changeStatus && this.status) {
        object.status = this.status.value.toLowerCase()
        if (this.scheduledAt) {
          object.scheduled_at = this.scheduledAt
        }
      }
      if (this.changeAuthors) {
        object.author_ids = this.authors.map((author) => author.id)
      }
      if (this.changeCategories) {
        object.categories = this.$refs.categoryList.selectedOptions
      }
      if (this.changeReviewer) {
        object.reviewer_id = this.reviewer
      }
      return object
    },
    pagesCountText() {
      return `${this.ids} ${this.ids.length === 1 ? 'page' : 'pages'}`
    },
    async remove() {
      if (this.ids.length > 10) {
        return this.showErrorMessages('You cannot delete more than 10 pages at a time')
      }
      if (await confirmDelete('Warning', `Are you sure want to delete ${this.ids.length} pages?`, 'Yes')) {
        this.loading = true
        await PagesService.bulkRemove({ ids: this.ids })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.clearBulkSelection()
              this.showSuccessMessage('Pages deleted')
              this.$emit('refresh')
              this.close()
            }
          })
          .catch(() => {
            this.showUnknownErrorMessage
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    clearBulkSelection() {
      this.$store.dispatch(`${this.stateName}/resetBulk`)
    },
    async submit() {
      if (Object.keys(this.buildUpdateObject()).length === 0) {
        this.showErrorMessages('Please add/choose values for bulk actions')
      } else {
        this.loading = true
        await PagesService.bulkUpdate(
          Object.assign(this.buildUpdateObject(), { ids: this.ids, site_id: this.siteId })
        )
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.clearBulkSelection()
              this.showSuccessMessage('Pages updated')
              this.$emit('refresh')
              this.close()
            }
          })
          .catch(() => {
            this.showUnknownErrorMessage
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    datePickerConfig() {
      return {
        altInput: true,
        altFormat: 'd/m/Y H:i',
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true,
        enableTime: true,
        minDate: moment().format(),
      }
    },
    getCategoryParams() {
      PagesService.get({ ids: this.ids })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            const templates = [...new Set(response.data.result.map((page) => page.template_id))]
            if (templates.length === 1 && this.$cookies.get('pages_filter').market_id) {
              this.categoryParams = {
                site_id: this.siteId,
                market_id: this.$cookies.get('pages_filter').market_id,
                page_type_id: templates[0],
              }

              this.categoriesVisible = true
            }

            this.available_statuses = response.data.result[0].available_statuses
          }
        })
        .catch(() => {
          this.showUnknownErrorMessage
        })
    },
  },
}
</script>
