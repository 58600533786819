<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: `/operators` }"
            data-tooltip="Back to operators"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ operator.name }}</span>
          <span class="text-muted mr-2">{{ operator.short_name }}</span>
          <span v-if="showCallout" class="callout callout-help float-right">
            Section to be deprecated. Add wagering info to Bonus section in Markets Data.
          </span>
        </h3>
        <div class="additional mb-2">
          <span>Last updated: {{ $dateRelative(operator.updated_at) }}</span>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3">
      <router-link tag="li" :to="{ path: `/operators/${operator.id}` }" exact>
        <a>General</a>
      </router-link>
      <router-link
        v-if="hasPermission('view.operators/bonuses')"
        tag="li"
        :to="{ path: `/operators/${this.operator.id}/markets-data` }"
      >
        <a>Markets Data</a>
      </router-link>
      <router-link
        v-if="hasPermission('view.operators/affiliate-operators')"
        tag="li"
        :to="{ path: `/operators/${this.operator.id}/sites-data` }"
      >
        <a>Sites Data</a>
      </router-link>
      <router-link
        v-if="hasPermission('view.toplists/lists')"
        tag="li"
        :to="{ path: `/operators/${this.operator.id}/toplists` }"
      >
        <a>Toplists</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import OperatorsService from '@services/OperatorsService.js'

export default {
  data() {
    return {
      standardisedLogo: null,
      showCallout: false,
      title: '',
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getOperator()
        }
      },
    },
  },
  mounted() {
    this.getOperator()
  },
  updated() {
    this.showCallout = this.$route.path.includes('/wagering')
  },
  computed: {
    isIdMismatch() {
      return this.operator?.id !== Number(this.$route.params['id'])
    },
    operator() {
      return { ...this.$store.state.editPage.model }
    },
    isLoading() {
      return this.$store.state.editPage.isLoading
    },
  },
  methods: {
    async getOperator() {
      await OperatorsService.getOne(this.$route.params['id'])
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            response.data.messages.some((message) => {
              if (message.indexOf('Operator not found') >= 0) {
                this.$router.push('/operators')
              }
            })
          } else {
            this.$store.commit('editPage/setModel', response.data.result[0])
            this.title = response.data.result[0].name
            if (this.$store.hasModule('data-table')) {
              this.$store.commit('data-table/setTitle', response.data.result[0].name)
              this.$store.commit(
                'data-table/setStandardisedLogo',
                response.data.result[0] && response.data.result[0].standardised_logo_asset_id
              )
            }
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$store.commit('editPage/setIsLoading', false)
        })
    },
    updateStatus(status) {
      OperatorsService.update({
        id: this.$route.params['id'],
        status: status,
        status_changed_by: this.$auth.user().id,
      })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage(`Status updated`)
            this.getOperator()
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
  },
  unmounted() {
    this.$store.commit('data-table/setStandardisedLogo', null)
    this.$store.commit('data-table/setTitle', '')
    if (this.isIdMismatch) {
      this.$store.commit('editPage/setModel', null)
      this.$store.commit('editPage/setIsLoading', true)
    }
  },
}
</script>
